<template>
  <div>
    <Menubar :model="menuItems" class="fadeinup animation-duration-300">
      <template #start>
        <img alt="logo" src="/img/logo.png" height="40" class="mr-3" />
      </template>
      <template #end>
        <Chip
          :label="notifyCount"
          icon="pi pi-bell"
          class="bg-primary mr-3"
          style="height: 35px"
          @click="toggleNotification"
        />
        <Button
          type="button"
          icon="pi pi-cog"
          class="p-button-text bg-transparent"
          style="color: black"
          @click="$router.push('/setting')"
        />
        <Button
          type="button"
          :label="userName"
          icon="pi pi-user"
          class="p-button-text bg-transparent"
          style="color: black"
          @click="onToggle"
        />
        <Menu ref="menu" :model="menuUser" :popup="true" />
      </template>
    </Menubar>
    <OverlayPanel
      ref="op"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '300px' }"
    >
      <template v-if="isMessageEmpty">
        <div
          class="flex align-items-center justify-content-center border-circle mr-3 flex-shrink-0"
        >
          <i class="pi pi-info-circle text-blue-500"></i>
        <span class="text-700 line-height-3">Tidak ada notifikasi.</span>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-column gap-3 w-25rem">
        <div>
          <div>
            <span class="font-medium text-900 block mb-2">Notifikasi</span>
          </div>
        </div>
      </div>
        <ul class="p-0 mx-0 mt-0 list-none">
          <li class="flex align-items-center py-2">
            <ul style="width: 100%; list-style-type: none" class="p-0 m-0">
              <li
                v-for="item in messages.slice().reverse()"
                :key="item.id"
                class="pl-2 pr-2 m-1"
                :style="{
                  border:
                    item.read_at === null
                      ? '2px solid #1364cb'
                      : '1px solid #dddddd',
                  backgroundColor: item.read_at === null ? '#eeeeee' : 'white',
                  borderRadius: '8px',
                }"
                @click="readMessage(item)"
              >
                <div>
                  <span class="font-medium">{{ item.data.title }}</span>
                  <div class="text-sm text-color-secondary">
                    {{ item.data.message }}
                  </div>
                </div>
                <div
                  class="flex align-items-center gap-2 text-color-secondary ml-auto text-sm"
                ></div>
              </li>
            </ul>
          </li>
        </ul>
      </template>
      <Button
        label="Tandai semua dibaca"
        class="w-full p-button-outlined p-button-secondary p-button-sm"
        v-if="!isMessageEmpty"
        @click="clearMessage"
      />
    </OverlayPanel>
    <Dialog
      v-model:visible="dialogLogout"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      closeable
    >
      <template #header>
        <div class="font-bold text-xl">
          <i class="pi pi-sign-out mr-2"></i> Logout
        </div>
      </template>
      Keluar dari aplikasi ?
      <template #footer>
        <Button
          label="Batal"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="dialogLogout = false"
        />
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          autofocus
          @click="$router.push('/logout')"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import Menubar from 'primevue/menubar'
import Chip from 'primevue/chip'
import { useAuthStore } from '@/store/auth'
// import { useAbility } from '@casl/vue'

const props = defineProps({
  messages: {
    type: Array,
    default() {
      return []
    },
  },
})

// const { can } = useAbility()
const dialogLogout = ref(false)
const auth = useAuthStore()
const menu = ref()
const op = ref()

const menuItems = ref([
  {
    label: 'Home',
    icon: 'pi pi-th-large',
    to: '/',
  },
  {
    label: 'Simpanan',
    icon: 'pi pi-clone',
    items: [
      {
        label: 'Simpanan',
        to: '/simpanan',
      },
      {
        separator: true,
      },
      {
        label: 'Penarikan',
        to: '/penarikan',
      },
    ],
  },
  {
    label: 'Pinjaman',
    icon: 'pi pi-money-bill',
    to: '/pinjaman',
  },
  {
    label: 'Anggota',
    icon: 'pi pi-users',
    to: '/anggota',
  },
  {
    label: 'Master',
    icon: 'pi pi-folder',
    items: [
      {
        label: 'Identitas Perusahaan',
        to: '/master/identitas-perusahaan',
      },
      {
        label: 'Jenis Simpanan',
        to: '/master/jenis-simpanan',
      },
      {
        label: 'Jenis Pinjaman',
        to: '/master/jenis-pinjaman',
      },
      {
        label: 'Jangka Pinjaman',
        to: '/master/jangka',
      },
      {
        separator: true,
      },
      {
        label: 'Jenis Penerimaan Kas',
        to: '/master/jenis-penerimaan',
      },
      {
        label: 'Jenis Pengeluaran Kas',
        to: '/master/jenis-pengeluaran',
      },
      {
        separator: true,
      },
      {
        label: 'User',
        items: [
          {
            label: 'User',
            to: '/master/user',
          },
          {
            label: 'Role & Permission',
            to: '/master/role-permission',
          },
        ],
      },
    ],
  },
  {
    label: 'Keuangan',
    icon: 'pi pi-calculator',
    items: [
      {
        label: 'Perkiraan Akuntansi',
        items: [
          {
            label: 'Head Akun',
            to: '/keuangan/head-akun',
          },
          {
            label: 'Kelompok Akun',
            to: '/keuangan/kelompok-akun',
          },
          {
            label: 'Perkiraan akun',
            to: '/keuangan/perkiraan-akun',
          },
        ],
      },
      {
        separator: true,
      },
      {
        label: 'Penerimaan Kas',
        to: '/keuangan/penerimaan',
      },
      {
        label: 'Pengeluaran Kas',
        to: '/keuangan/pengeluaran',
      },
      {
        label: 'Jurnal Umum',
        to: '/keuangan/jurnal-umum',
      },
      {
        label: 'Neraca Awal',
        to: '/keuangan/neraca-awal',
      },
    ],
  },
  {
    label: 'Laporan',
    icon: 'pi pi-book',
    to: '/laporan',
  },
])
const menuUser = ref([
  {
    label: 'Profile',
    icon: 'pi pi-user-edit',
    to: '/profile',
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      logout()
    },
  },
])

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['reads', 'clear'])

const userName = computed(() => {
  return auth.user ? auth.user.name : ''
})

const notifyCount = computed(() => {
  let unread = 0
  if (props.messages) {
    props.messages.forEach((item) => {
      const reads = item.read_at == null
      if (reads) unread++
    })
  }
  return unread.toString()
})

const isMessageEmpty = computed(() => {
  return props.messages.length === 0
})

function onToggle(event) {
  menu.value.toggle(event)
}

function toggleNotification(event) {
  op.value.toggle(event)
}

function logout() {
  dialogLogout.value = true
}

function readMessage(item) {
  const reads = item.data
  if (reads) {
    emit('reads', item)
  }
}

function clearMessage() {
  emit('clear')
}
</script>

<style scoped>
.p-menubar {
  /* background: #fccb33; */
  border: none;
  border-bottom: 2px solid #1364cb;
  border-radius: 0;
}
</style>
