import { defineStore } from 'pinia'
import http from '@/clients/Axios'
import { useStorage } from '@vueuse/core'
import defineAbilitiesFor from '@/services/rules'
import { isEmail, isNumber } from '@/helpers'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: useStorage('user', {}),
    userData: {},
    permissions: [],
  }),
  getters: {
    isAuthenticated() {
      return this.user ? true : false
    },
    isAdmin() {
      return this.user && this.user.role === 'admin'
    },
    isKetua() {
      return this.user && this.user.role === 'ketua'
    },
  },
  actions: {
    async login(login, password) {
      let loginForm = null

      if (isNumber(login)) {
        loginForm = {
          phone: login,
          password: password,
        }
      } else if (isEmail(login)) {
        loginForm = {
          email: login,
          password: password,
        }
      } else {
        loginForm = {
          username: login,
          password: password,
        }
      }
      await http.post('/auth/login', loginForm).then(async (res) => {
        if (res.data.status === 200) {
          localStorage.setItem('aid', res.data.data.token)
          this.user = res.data.data.user
          // http.get('/user').then((res) => {
          //   if (res.data.message === 'success') {
          //     this.user = res.data.data
          // ability.update(defineAbilitiesFor(this.user.permissions))
          // }
          // })

          // Clear permissions array before adding new permissions
          this.permissions = []

          // this.user.roles.forEach((role) => {
          //   this.permissions.push(
          //     ...role.permissions.map((permission) => permission.name)
          //   )
          // })

          // ability.update(defineAbilitiesFor(this.permissions))
        }
      })
    },
    async refreshPermission(ability) {
      // Clear permissions array before adding new permissions
      // this.permissions = []

      // this.user.roles.forEach((role) => {
      //   this.permissions.push(
      //     ...role.permissions.map((permission) => permission.name)
      //   )
      // })

      if (this.user && this.permissions.length > 0) {
        ability.update(defineAbilitiesFor(this.permissions))
      }
    },
    logout() {
      localStorage.clear()
      this.user = null
    },
  },
})
