import http from '../clients/Axios'

export default class NotificationService {
  async get() {
    const res = await http.get('/notifications')
    return res.data
  }

  async reads(id) {
    const res = await http.post(`/notifications/${id}`)
    return res
  }

  async readAll() {
    const res = await http.post(`/notifications/reads`)
    return res
  }

}
