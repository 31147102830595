<template>
  <component :is="$route.meta.layout || 'div'">
    <Toast />
    <nav-bar
      v-if="auth.isAuthenticated"
      :messages="messages"
      @reads="onMessageReads"
      @clear="onMessageClear"
    />
    <router-view class="m-4" />
    <div class="text-xs ml-4 mb-4">
      <span class="uppercase">{{ appName }} {{ appVersion }} </span>
      <br />
      <span class="uppercase">{{ appCompany }}</span>
    </div>
    <Dialog
      :header="headerUpdate"
      v-model:visible="refreshDialog"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :closable="false"
    >
      <p class="m-0">
        Update aplikasi telah tersedia. Klik tombol Refresh untuk memperbaharui.
      </p>
      <template #footer>
        <Button
          label="Refresh"
          icon="pi pi-check"
          @click="updateApp"
          autofocus
        />
      </template>
    </Dialog>
    <ScrollTop />
  </component>
</template>

<script>
import ScrollTop from 'primevue/scrolltop'
import NavBar from './components/NavBar.vue'
import errorHandler from '@/helpers/error-handler'
import { useAuthStore } from '@/store/auth'
import SettingService from '@/services/SettingService'
import ProfileService from '@/services/ProfileService'
import NotificationService from '@/services/NotificationService'

export default {
  components: { NavBar, ScrollTop },
  inject: ['workbox', 'ability'],
  created() {
    this.settingService = new SettingService()
    this.profileService = new ProfileService()
    this.notificationService = new NotificationService()
    this.auth = useAuthStore()
    if (this.workbox) {
      this.workbox.addEventListener('waiting', () => {
        this.refreshDialog = true
      })
    }
  },
  data() {
    return {
      cabang: null,
      cabangService: null,
      refreshDialog: false,
      messages: [],
      auth: {},
      appName: process.env.VUE_APP_APP_NAME,
      appVersion: process.env.VUE_APP_VERSION,
      dbVersion: '',
      appCompany: '',
    }
  },
  mounted() {
    if (this.auth.isAuthenticated) {
      this.profileService.get().then((res) => {
        if (res.data.data) {
          this.appCompany = res.data.data.nama
        }
      })

      this.notificationService.get().then((res) => {
        if (res.data) {
          this.messages = res.data
        }
      })

      this.auth.refreshPermission(this.ability)
    }
  },
  computed: {
    headerUpdate() {
      //  return 'Update ' + this.appVersion + ' telah tersedia';
      return 'Update telah tersedia'
    },
  },
  methods: {
    async updateApp() {
      this.refreshDialog = false
      await this.workbox.messageSW({ type: 'SKIP_WAITING' })
    },
    onMessageReads(data) {
      console.log('cek data', data)
      this.notificationService
        .reads(data.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$router.push({
              name: 'PinjamanEdit',
              params: { id: data.data.pinjaman_id },
            })

            this.notificationService.get().then((response) => {
              if (response.data) {
                this.messages = response.data
              }
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Notifikasi', this)
        })
    },
    onMessageClear() {
      this.notificationService
        .readAll()
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Notifikasi',
              detail: 'Notifikasi ditandai baca semua.',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Notifikasi', this)
        })

    }
  },
}
</script>
