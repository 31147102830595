import http from '../clients/Axios'

export default class ProfileService {
  // Identitas Perusahaan
  async get() {
    return await http.get('/profile')
  }

  async add(form) {
    const res = await http.post('/profile', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/profile/${id}`, form)
    return res
  }
}
