import http from '../clients/Axios'

export default class SettingService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param = filters_param + '&' + el + '=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/setting/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get() {
    const res = await http.get('/setting')
    return res.data
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/setting${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/setting', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/setting/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/setting/${id}`)
    return res
  }

  setVersion(version) {
    localStorage.setItem('version', version)
  }

  getVersion() {
    return localStorage.getItem('version')
  }
}
