<template>
  <div>
    <div class="mb-4 flex align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Home</span>
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'

// import { ABILITY_TOKEN } from '@casl/vue'

export default {
  name: 'Home',
  /* inject: {
    $ability: { from: ABILITY_TOKEN },
  }, */
  data() {
    return {}
  },
  mounted() {},
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value, 2, 2)
    },
  },
}
</script>
